import { useState } from 'react'
import { useDropzone } from 'react-dropzone'

import { TUploadFileComponent } from './types'
import ErrorModal from './modals/ErrorModal'
import Icon from '../../../components/Icon'
import Text from '../../../components/text'
import Button from '../../../components/button'
import Container from '../../../components/container'
import AvatarLogo from '../../../components/AvatarLogo'
import AddLinkInput from '../../../components/AddLinkInput'
import DeleteFileConfirmationModal from './modals/DeleteFileConfirmationModal'
import {
  convertBytes,
  createUrlForFiles,
  getListOfTypeOfFilesAllowed,
  removeDuplicatedFiles
} from './utils'
import { FilesDragContainer, FilesDragWrapper, UploadFileWrapper } from './styles'
import { useFormContext } from '../../../context/FormContext'
import FilesPreview from './FilesPreview'

const UploadFile: React.FC<TUploadFileComponent> = ({ stepInfo }) => {
  const [fileToDelete, setFileToDelete] = useState<string>()
  const [errorModalDocument, setErrorModalDocument] = useState<string>()
  const {
    logoFromChooseAvatar,
    content: { maxAmountFiles, typeOfFilesAllowed, maxBites }
  } = stepInfo
  const { goToNextStep, goToPreviousStep, files, setFiles, editFormContent, formContent } =
    useFormContext()
  const [httpsLink, setHttpsLink] = useState<string>((formContent['link'] as string) || '')

  const { getRootProps, getInputProps, open } = useDropzone({
    maxSize: maxBites,
    maxFiles: maxAmountFiles,
    accept: typeOfFilesAllowed,
    onDrop: (acceptedFiles, fileRejections) => {
      const errorCode = fileRejections?.[0]?.errors?.[0]?.code
      if (errorCode) setErrorModalDocument(errorCode)

      const newFiles = createUrlForFiles(acceptedFiles)
      const cleanArray = removeDuplicatedFiles([...files, ...newFiles])
      setFiles(cleanArray)
    }
  })

  const getModalContent = () => {
    if (!errorModalDocument) return undefined
    if (errorModalDocument === 'file-too-large' && maxBites)
      return `O tamanho do arquivo excedeu o máximo permitido de ${convertBytes(maxBites)}.`
    if (errorModalDocument === 'file-invalid-type' && typeOfFilesAllowed)
      return `Essa extensão não está entre as permitidas${getListOfTypeOfFilesAllowed(typeOfFilesAllowed)}`

    return 'Tivemos um problema ao anexar seu arquivo, Tente novamente'
  }

  const handleDeleteFile = () => {
    setFiles(currentFiles => currentFiles.filter(item => item.name !== fileToDelete))
    setFileToDelete(undefined)
  }

  const handleSubmit = () => {
    editFormContent({ link: httpsLink })
    goToNextStep()
  }

  const showName =
    logoFromChooseAvatar?.showName !== undefined ? logoFromChooseAvatar?.showName : true

  return (
    <>
      <DeleteFileConfirmationModal
        fileToDelete={fileToDelete}
        handleDeleteModal={handleDeleteFile}
        handleCloseModal={() => setFileToDelete(undefined)}
      />
      <ErrorModal
        errorMessage={getModalContent()}
        handleCloseModal={() => setErrorModalDocument(undefined)}
      />
      <UploadFileWrapper>
        <FilesDragWrapper>
          {logoFromChooseAvatar ? (
            <Container justifyContent='center'>
              <AvatarLogo stepInfo={stepInfo} showName={showName} size='180px' />
            </Container>
          ) : null}
          <FilesDragContainer>
            {maxAmountFiles && files?.length === maxAmountFiles ? (
              <Text
                font='body'
                fontWeight='800'
                pl={3}
                value={`${maxAmountFiles} de ${maxAmountFiles} arquivos foram anexados. Para adicionar outro arquivo, exclua algum deles.`}
              />
            ) : (
              <div {...getRootProps({ className: 'dropzone' })}>
                <div onClick={e => e.stopPropagation()}>
                  <input {...getInputProps()} />
                  <Icon name='upload' size={50} />
                  <Button
                    onClick={open}
                    value='Clique ou arraste para anexar seu portfólio e/ou currículo'
                    variant='link'
                  />
                </div>
              </div>
            )}
            <FilesPreview files={files} setFileToDelete={setFileToDelete} />
          </FilesDragContainer>
        </FilesDragWrapper>
        <AddLinkInput value={httpsLink} onChange={({ target }) => setHttpsLink(target.value)} />
      </UploadFileWrapper>

      <Container
        p='2rem 1rem'
        mt='auto'
        width='100%'
        alignItems='center'
        justifyContent='space-between'
        flexDirection='row-reverse'
      >
        <Button onClick={handleSubmit} value='PRÓXIMO >' />
        <Button onClick={goToPreviousStep} variant='outline' value='< ANTERIOR' />
      </Container>
    </>
  )
}

export default UploadFile

import styled, { css } from 'styled-components'
import { space, SpaceProps } from 'styled-system'

export const StyledStepsContainer = styled.div<SpaceProps>`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  ${space};
`

export const StyledBreadcrumbOl = styled.ol`
  list-style: none;
  display: flex;
  padding: 0;
  align-items: center;
`

export const StyledLi = styled.li<{ isActive: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  transition: all 300ms;

  &:focus,
  &:focus-within {
    button {
      border: 3px solid white;
      outline: 3px solid ${props => props.theme.colors.secondary};
    }
  }

  &[aria-current='true'] {
    border: 3px solid ${props => props.theme.colors.secondary};

    button {
      background-color: ${props => props.theme.colors.secondary};
      border: 2.5px solid #fff;
    }
  }

  button {
    width: 32px;
    height: 32px;
    border: none;
    padding: 0;
    font-size: 21px;
    line-height: 0;
    font-weight: 600;
    color: ${props => props.theme.colors.white};
    border-radius: 50%;
    cursor: pointer;
    background-color: ${props => props.theme.colors.gray[200]};
    &:disabled {
      width: 30px;
      height: 30px;
    }

    ${({ isActive, theme }) =>
      isActive &&
      css`
        background-color: ${theme.colors.secondary};
      `}
  }
`

export const StyledSeparator = styled.li<{ isActive: boolean }>`
  width: 24px;
  height: 3px;
  background-color: ${props =>
    props.isActive ? props.theme.colors.secondary : props.theme.colors.gray[200]};

  @media screen and (max-width: 1024px) {
    width: 12px;
  }
`

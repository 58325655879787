import { useTheme } from 'styled-components'

import Text from '../../../components/text'
import Button from '../../../components/button'
import Container from '../../../components/container'
import AvatarToSomething from './Layouts/AvatarToSomething'
import { useFormContext } from '../../../context/FormContext'
import { TInformationStepComponent } from './types'
import { useLayoutContext } from '../../../context/LayoutContext'

const InformationStep: React.FC<TInformationStepComponent> = ({ stepInfo }) => {
  const theme = useTheme()
  const { isMobile } = useLayoutContext()
  const { goToNextStep, avatarInfo, goToPreviousStep, currentStep, isFinalStep } = useFormContext()
  const {
    textColor,
    logoFromChooseAvatar,
    content: { altImage, image, button, layout, imgHeight, imgWidth }
  } = stepInfo

  const showAvatar = logoFromChooseAvatar && avatarInfo && layout === 'avatarToSomething'

  return (
    <Container
      flexDirection='column'
      alignItems='center'
      height={currentStep !== 0 && !isFinalStep ? '100%' : 'unset'}
      mx='auto'
      width='100%'
      px='1rem'
    >
      <Container m='auto' flexDirection='column' justifyContent='center' alignItems='center'>
        {showAvatar ? (
          <AvatarToSomething stepInfo={stepInfo} />
        ) : (
          <img
            alt={altImage}
            src={image}
            width={imgWidth}
            height={!isMobile ? imgHeight : undefined}
            style={{ maxWidth: '100%', margin: isMobile ? '20px 0' : '0 0 60px' }}
          />
        )}
        <Text
          mt={!showAvatar && image ? 3 : undefined}
          color={textColor || theme.colors.black}
          textAlign='center'
          font='body'
        >
          {stepInfo.description}
        </Text>
      </Container>
      {button && (
        <Container
          p='2rem 1rem'
          mt='auto'
          width='100%'
          alignItems='center'
          justifyContent={button.backButton ? 'space-between' : 'space-evenly'}
          flexDirection='row-reverse'
        >
          {button.action === 'goToNextPage' ? (
            <Button
              onClick={goToNextStep}
              width='fit-content'
              value=''
              display='flex'
              variant={button.variant}
            >
              {button.leftIcon && <img src={button.leftIcon} alt='' />}
              {button.text}
            </Button>
          ) : null}
          {button.backButton ? (
            <Button onClick={goToPreviousStep} variant='outline' value='< ANTERIOR' />
          ) : null}
        </Container>
      )}
    </Container>
  )
}

export default InformationStep

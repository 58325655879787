import styled from 'styled-components'
import { GridProps, grid } from 'styled-system'

export const Complex = styled.img`
  max-width: 200px;
`

export const MultipleCheckboxBadgedContainer = styled.ul`
  width: 100%;
  padding: 0;
  margin: 2rem 0;
  list-style: none;

  gap: 1rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`

export const MultipleCheckboxBadged = styled.li`
  border: 1px solid ${({ theme }) => theme.colors.primary};
  border-radius: 10px;
  padding: 5px 10px;

  display: flex;
  align-items: center;
  gap: 0.5rem;

  &[aria-describedby='badged-description'] {
    background-color: ${({ theme }) => theme.colors.primary};
    color: ${({ theme }) => theme.colors.white};

    svg {
      fill: ${({ theme }) => theme.colors.white};
    }
    button {
      color: ${({ theme }) => theme.colors.white};
    }
  }

  &:focus,
  &:hover {
    box-shadow: 0 0 0 1.5px inset ${({ theme }) => theme.colors.primary};
  }

  button {
    background: transparent;
    border: none;
    padding: 0;

    &:disabled {
      color: ${({ theme }) => theme.colors.black};
    }
  }

  p {
    margin: 0;
  }
`

export const MultipleCheckboxContainer = styled.div`
  width: 96%;
  height: 96%;
  overflow-y: auto;
  overflow-x: hidden;
  display: grid;

  ::-webkit-scrollbar {
    width: 4px;
  }

  ::-webkit-scrollbar-track {
    background-color: #d9d9d9;
    border: 1px solid #d9d9d9;
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colors.primary};
  }
`

export const MultipleCheckboxWrapper = styled.div`
  width: 100%;
  height: 250px;
  border-radius: 10px;
  background-color: #fff;
  border: 1px solid ${props => props.theme.colors.primary};
  padding: 3px 0;

  display: flex;
  justify-content: center;
  align-items: center;

  label {
    width: 100%;
  }

  ${MultipleCheckboxContainer} > div > div {
    padding: 0 2rem;
    display: grid;

    > div {
      padding: 0.13rem 0;
    }

    &:hover {
      background-color: ${({ theme }) => theme.colors.gray[100]};
    }

    + div > div {
      border-top: 1px solid ${props => props.theme.colors.gray[300]};
    }

    @media screen and (max-width: 768px) {
      padding: 0;
    }
  }
`

export const GridContainer = styled.div<GridProps>`
  display: grid;
  ${grid}

  padding: 0 2rem;
  gap: 1rem;
  row-gap: 1.5rem;

  @media screen and (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
`

import { Formik, FormikHelpers } from 'formik'
import { ConnectedFocusError } from 'focus-formik-error'

import Button from '../../../components/button'
import Container from '../../../components/container'
import SimpleLayoutStrategy from '../../../utils/strategy/simpleLayoutStrategy'
import { yupValidationFactory } from '../../../utils/schemaToYup'
import { useFormContext } from '../../../context/FormContext'
import { TSimpleFormComponent } from './types'
import { StyledForm } from './styles'

const SimpleForm: React.FC<TSimpleFormComponent> = ({ stepInfo }) => {
  const { editFormContent, goToNextStep, currentStep, steps, goToPreviousStep, formContent } =
    useFormContext()

  const {
    id,
    content: { layout, fields }
  } = stepInfo
  const showActionsButtons = currentStep !== 0 && currentStep !== steps.length - 1
  const yupValidationSchema = yupValidationFactory(fields)

  const getInitialValues = () => {
    const previousContent = (formContent[id] || {}) as Record<string, unknown>
    const initialValues = fields.reduce((acc, field) => {
      if (field.type !== 'select') return acc

      return { ...acc, [field.id]: field.options[0].id }
    }, {})

    return { ...initialValues, ...previousContent }
  }

  const handleSubmitForm = (
    values: Record<string, unknown>,
    { resetForm }: FormikHelpers<Record<string, unknown>>
  ) => {
    editFormContent({ [id]: values })
    resetForm({ values: {} })
    goToNextStep()
  }

  const stepComponent = () => SimpleLayoutStrategy[layout]

  return (
    <Formik
      enableReinitialize
      onSubmit={handleSubmitForm}
      initialValues={getInitialValues()}
      validationSchema={yupValidationSchema}
    >
      {({ handleSubmit, ...formikProps }) => (
        <StyledForm onSubmit={handleSubmit}>
          <ConnectedFocusError />
          {stepComponent()({ stepInfo, formikProps })}
          {showActionsButtons && (
            <Container
              m='auto'
              p='2rem 0'
              width='100%'
              alignItems='center'
              justifyContent='space-between'
              flexDirection='row-reverse'
            >
              <Button type='submit' value='PRÓXIMO >' />
              <Button onClick={goToPreviousStep} variant='outline' value='< ANTERIOR' />
            </Container>
          )}
        </StyledForm>
      )}
    </Formik>
  )
}

export default SimpleForm

import React, { useCallback, useContext, useEffect, useState } from 'react'
import { TAvatar } from '../../types/AdditionalTypes'
import { TForm } from '../../types/types'
import { Props, TCustomFile, TFormContext } from './types'
import { getForm, postFormAnswers, postFormFiles } from '../../api/Form'
import Loading from '../../components/Loading'
import ErrorPage from '../../components/Error'
import { cleanObjectForSubmit } from '../../utils'
import { AxiosError } from 'axios'

export const FormContext = React.createContext<(TForm & TFormContext) | null>(null)

export const uploadFiles = async (files: Blob[]) => {
  const formData = new FormData()
  files.map(file => formData.append('files', file))

  const response = await postFormFiles(formData)
  return response
}

const FormContextProvider = ({ children }: Props) => {
  const [currentStep, setCurrentStep] = useState(0)
  const [lastVisitedStep, setLastVisitedStep] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [avatarInfo, setAvatarInfo] = useState<TAvatar>()
  const [formContent, setFormContent] = useState<Record<string, unknown>>({})
  const [files, setFiles] = useState<TCustomFile[]>([])
  const [formData, setFormData] = useState<TForm>()
  const [hasError, setHasError] = useState<string>()
  const formId = window.location.pathname.split('/')[2]

  const totalSteps = formData?.steps.length
  const isFinalStep = currentStep + 1 === totalSteps

  const fetchFormData = useCallback(async () => {
    setIsLoading(true)

    try {
      if (!formId) throw new Error()
      const { data } = await getForm(formId)

      setFormData(data)
    } catch (error) {
      const wrongLink = !formId || (error as AxiosError).code === 'ERR_BAD_REQUEST'
      const errorMsg = wrongLink
        ? 'O link não parece certo, por favor entre em contato!'
        : 'Algo deu errado com nossos servidores, por favor entre em contato'

      setHasError(errorMsg)
    }

    setIsLoading(false)
  }, [formId])

  const restoreContent = useCallback(() => {
    try {
      const savedContent = sessionStorage.getItem(`${formId}-matchmaking-form-responses`)
      if (!savedContent) return null

      const restoredContentParsed = JSON.parse(savedContent)
      setFormContent(restoredContentParsed)

      if (restoredContentParsed.avatar) setAvatarInfo(restoredContentParsed.avatar)
    } catch {
      sessionStorage.removeItem(`${formId}-matchmaking-form-responses`)
    }
  }, [formId])

  useEffect(() => {
    restoreContent()
    fetchFormData()
  }, [fetchFormData, restoreContent])

  const cleanForm = () => {
    setLastVisitedStep(0)
    setFormContent({})
    setFiles([])
    sessionStorage.removeItem(`${formId}-matchmaking-form-responses`)
  }

  const handleSubmit = async (newFormContent: Record<string, unknown>) => {
    setIsLoading(true)
    const cleanObject = cleanObjectForSubmit(newFormContent)

    try {
      if (!formId) throw new Error()
      if (files.length) {
        const urls = await uploadFiles(files)
        cleanObject.files = urls.data.files
      }
      await postFormAnswers(formId, cleanObject)

      cleanForm()
    } catch (error) {
      setHasError('Algo deu errado com nossos servidores, por favor entre em contato')
      console.error(error)
    }

    setIsLoading(false)
  }

  const clickOnLogo = () => {
    const totalSteps = formData?.steps.length

    const isFinalStep = currentStep + 1 === totalSteps
    if (isFinalStep) setAvatarInfo(undefined)

    changeStepTo(0)
  }

  const editFormContent = async (newItem: Partial<Record<string, unknown>>) => {
    const newFormContent = { ...formContent, ...newItem }
    setFormContent(newFormContent)

    sessionStorage.setItem(`${formId}-matchmaking-form-responses`, JSON.stringify(newFormContent))

    const isFinalStep = currentStep + 2 === totalSteps
    if (isFinalStep) handleSubmit(newFormContent)
  }

  const changeStepTo = (step: number) => setCurrentStep(step)
  const goToPreviousStep = () => currentStep - 1 >= 0 && setCurrentStep(currentStep - 1)
  const goToNextStep = () => {
    if (!formData) return null
    const totalSteps = formData?.steps.length
    totalSteps > currentStep + 1 && setCurrentStep(currentStep + 1)

    if (currentStep + 1 > lastVisitedStep) {
      setLastVisitedStep(currentStep + 1)
    }
  }

  if (hasError) return <ErrorPage isInitialError description={hasError} hideButton={!formId} />
  if (!formData) return <Loading color='#408ec5' fullPage />

  return (
    <FormContext.Provider
      value={{
        ...formData,
        files,
        isLoading,
        avatarInfo,
        currentStep,
        isFinalStep,
        formContent,
        lastVisitedStep,
        setFiles,
        clickOnLogo,
        changeStepTo,
        goToNextStep,
        goToPreviousStep,
        setAvatarInfo,
        setIsLoading,
        editFormContent
      }}
    >
      {children}
    </FormContext.Provider>
  )
}

export const useFormContext = () => {
  const context = useContext(FormContext)

  if (context) return context

  throw new Error('useFormContext must be used within a FormContextProvider.')
}

export default FormContextProvider
